<template>
  <v-row justify="space-around">
      <v-dialog
        transition="dialog-top-transition"
        v-model="dialogalert"
      >
        <template >
        <v-alert
        type="success"
        >PQR Calidad Asignada Correctamente</v-alert>
        </template>
      </v-dialog>
  </v-row>
</template>

<script >
export default {
  name: 'dialogAlertAsignar',
  props: ['dialogalert'],
  comments: {

  },
  mixins: [],
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
