<template>
<div style="width:100%">
  <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CALIDAD ASIGNACIÓN</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <asignarCalidadComponent :dialog="dialog" :colores="colores" :session="session" @closed_ventana="alert"></asignarCalidadComponent>
        <asignarAlert :dialogalert="dialogalert"></asignarAlert>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import asignarCalidadComponent from './asignarCalidadComponent.vue'
import asignarAlert from './asignarAlertComponent.vue'

export default {
  name: 'reasignarDialogComponent',
  components: {
    asignarCalidadComponent,
    asignarAlert
  },
  props: ['dialog', 'session', 'colores', 'rules'],
  data: () => ({
    dialogalert: false
  }),
  methods: {
    alert () {
      this.dialogalert = true
      setTimeout(() => {
        this.dialogalert = false
        this.$emit('closed_asignar_calidad')
      }, 2000)
    },
    cerrarVenta () {
      this.$emit('closed_asignar_calidad')
    }
  }
}
</script>
