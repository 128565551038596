import axios from 'axios'
export default {
  methods: {
    rec_get_asesor (areaid) {
      const urlroute = this.$store.getters.getUrl + 'asesores_area_reasignar/' + areaid
      this.users = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.users = response.data
        if (areaid === 5) {
          for (let index = 0; index < this.users.length; index++) {
            const element = this.users[index]
            if (element.id_usuario !== 42568) {
              this.users = []
              this.users.push(element)
              break
            }
          }
        }
        for (let index = 0; index < this.users.length; index++) {
          const element = this.users[index]
          element.usuario.nombre_usuario = element.usuario.nombre_usuario + ' ' + element.usuario.apellido_usuario
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingArea = false
      })
    },
    rec_bandeja_mis_pqrs (pUsuarioId) {
      if (pUsuarioId > 0) {
        const urlroute = this.$store.getters.getUrl + 'badeja_mis_pqrs/' + pUsuarioId
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.pqrsUser = response.data
          if (this.items.item_registros_sin_filtro) {
            this.items.item_registros_sin_filtro = response.data
            this.items.items_registros = this.items.item_registros_sin_filtro
          }
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_reasignar_pqrs (pqrs, idusuario, idescaladoa) {
      if (pqrs.length > 0) {
        const urlroute = this.$store.getters.getUrl + 'reasignar_pqrs'
        const data = { pqrs: pqrs, id_usuario: idusuario, id_escaladoa: idescaladoa }
        axios({
          url: urlroute,
          method: 'POST',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_restablecer_clave (idusuario, clave) {
      const urlroute = this.$store.getters.getUrl + 'restablecer_clave'
      const data = { id_usuario: idusuario, clave: clave }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    }
  }
}
