<template>
<div style="width:100%">
  <v-list>
    <div v-for="(item, index) in session.permisos" :key="index">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar  color="indigo" size="36"><span class="white--text text-h6"> {{ (index + 1) }} </span></v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title>{{ item.modulo.titulo }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
              <v-btn @click="abrirVentana(item)" icon>
                <v-icon color="grey lighten-1">{{ item.modulo.icono }}</v-icon>
              </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
    </div>
  </v-list>
  <dialogoEstadisticas :dialog="dialogEstadisticas" :session="session" :colores="colores" :rules="rules" :items="items" @closed_estadisticas="cerrar_estadisticas"></dialogoEstadisticas>
  <dialogoReasignar :dialog="dialogReasignar" :session="session" :colores="colores" :rules="rules" @closed_reasignar="cerrar_reasignar"></dialogoReasignar>
  <dialogoRestablecer  :dialog="dialogRestablecer" :session="session" :colores="colores" :rules="rules" @closed_restablecer="cerrar_restablecer"></dialogoRestablecer>
  <dialogoCalidad  :dialog="dialogAsignarCalidad" :session="session" :colores="colores" :rules="rules" @closed_asignar_calidad="cerrar_asignar_calidad"></dialogoCalidad>
</div>
</template>

<script>
import dialogoEstadisticas from '@/components/estadisticas/dialogoComponent.vue'
import dialogoReasignar from '@/components/reasignar/reasignarDialogoComponent.vue'
import dialogoRestablecer from '@/components/restablecer/restablecerDialogoComponent.vue'
import dialogoCalidad from '../asignarcalidad/asignarCalidadDialogoComponent.vue'
export default {
  name: 'aplicativosComponent',
  components: {
    dialogoEstadisticas,
    dialogoReasignar,
    dialogoRestablecer,
    dialogoCalidad
  },
  props: ['session', 'colores', 'rules', 'items'],
  data: () => ({
    dialogEstadisticas: false,
    dialogReasignar: false,
    dialogRestablecer: false,
    dialogAsignarCalidad: false
  }),
  created () {
  },
  methods: {
    abrirVentana (item) {
      if (item.modulo_id === 1) {
        this.dialogEstadisticas = true
      }
      if (item.modulo_id === 2) {
        this.dialogReasignar = true
      }
      if (item.modulo_id === 3) {
        this.dialogRestablecer = true
      }
      if (item.modulo_id === 4) {
        this.dialogAsignarCalidad = true
      }
    },
    cerrar_estadisticas () {
      this.dialogEstadisticas = false
    },
    cerrar_reasignar () {
      this.dialogReasignar = false
    },
    cerrar_restablecer () {
      this.dialogRestablecer = false
    },
    cerrar_asignar_calidad () {
      this.dialogAsignarCalidad = false
    }
  }
}
</script>
