import axios from 'axios'
export default {
  methods: {
    async rec_estadisticas_anio (anio) {
      return new Promise((resolve, reject) => {
        const urlroute = this.$store.getters.getUrl + 'estadistica_anula/' + anio
        this.arregloDataAnio = []
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.arregloDataAnio = response.data
          this.esperar = false
          resolve('Data anio')
        }).catch(error => {
          console.log('error ' + error)
          reject(error)
        }).finally(() => {
        })
      })
    },
    organizarDataSubEstados (data) {
      this.arregloDataSubEstados = []
      var arregloDataSubEstadosTemp = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (((element.descripcion !== 'RADICADO' && element.descripcion !== 'ANULADO'))) {
          this.arregloDataSubEstados.push(element)
        }
      }
      for (let index = 0; index < this.arregloDataSubEstados.length; index++) {
        const element = this.arregloDataSubEstados[index]
        if (element.color === 'indigo\r\n') {
          element.descripcion = 'RECIBIDO CORREO'
          this.arregloDataSubEstados[index].descripcion = element.descripcion
        }
        if (element.color === 'pink') {
          element.descripcion = 'RECIBIDO SFC'
          this.arregloDataSubEstados[index].descripcion = element.descripcion
        }
        if (element.color === 'brown') {
          element.descripcion = 'RECIBIDO MICROSITIO'
          this.arregloDataSubEstados[index].descripcion = element.descripcion
        }
      }
      arregloDataSubEstadosTemp = this.arregloDataSubEstados
      this.arregloDataSubEstados = []
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[5])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[6])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[7])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[0])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[4])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[3])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[2])
      this.arregloDataSubEstados.push(arregloDataSubEstadosTemp[1])
    },
    organizarDataFinal (mes, fil, col) {
      let cantidadTotal = 0
      const element1 = this.DataAnio.anuales
      if (element1.length > 0) {
        for (let index = 0; index < element1.length; index++) {
          const element2 = element1[index]
          if (col === 0) { // Suma total De Cada tipo
            if (element2.mes === mes && element2.tipo_id === fil) {
              cantidadTotal = cantidadTotal + element2.cantidad
            }
          }
          if (col === 8) { // Cantidad de Casos Correo
            if (element2.mes === mes && element2.subestado_id === col) {
              return element2.cantidad
            }
          } else if (fil > 0) { // Suma total De Sin tipo
            if (element2.mes === mes && element2.tipo_id === fil && element2.subestado_id === col) {
              return element2.cantidad
            }
          }
        }
      }
      if (cantidadTotal > 0) {
        return cantidadTotal
      }
    },
    organizarDataAbiertosJS (col) {
      const element1 = this.arregloDataAnio.casosTotales
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    generar_excelAbiertos (anio) {
      const urlroute = this.$store.getters.getUrl + 'excelAbiertos/' + anio
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Abiertos_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    organizarDataEscaIntJS (col) {
      const element1 = this.arregloDataAnio.dataCasosEcsTotales.casosTotalesEscInt
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    organizarDataEscaOtroJS (col) {
      const element1 = this.arregloDataAnio.dataCasosEcsTotales.casosTotalesEscOtro
      for (let index = 0; index < Object.values(element1).length; index++) {
        const element2 = element1[index]
        if (index === col) {
          return element2
        }
        if (col === -1) {
          return element1.total
        }
      }
    },
    generar_excelFinal (anio, mes) {
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      const urlroute = this.$store.getters.getUrl + 'excelFinal/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Reporte_' + mesLet + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelAsignaciones (anio, mes) {
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      const urlroute = this.$store.getters.getUrl + 'excelAsignaciones/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Asignaciones_' + mesLet + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelEscalamientos (anio, mes) {
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      const urlroute = this.$store.getters.getUrl + 'excelEscalamientos/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Escalamientos_' + mesLet + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelAnalisis (anio, mes) {
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      const urlroute = this.$store.getters.getUrl + 'excelAnalisis/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Analisis_' + mesLet + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelReplicas (anio, mes) {
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      const urlroute = this.$store.getters.getUrl + 'excelReplica/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Replicas_' + mesLet + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    async rec_estadisticas_mes (anio, mes) {
      return new Promise((resolve, reject) => {
        if (mes > 0 && mes < 10) {
          mes = '0' + mes
        }
        const urlroute = this.$store.getters.getUrl + 'estadistica_mes/' + anio + '/' + mes
        this.arregloDataMes = []
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.arregloDataMes = response.data
          this.esperar = false
          resolve('Data Mes')
        }).catch(error => {
          console.log('error ' + error)
          reject(error)
        }).finally(() => {
        })
      })
    },
    async organizarLabelsJS (DataMes, items) {
      // Data Analistas
      for (let index = 0; index < DataMes.analistas.analistasLista.length; index++) {
        const element = DataMes.analistas.analistasLista[index]
        if (element.asesor.area_id === 5 || element.asesor.area_id === 14 || element.asesor.area_id === 15) {
          this.labels_analista.push(element.escaladoa.nombre_usuario + ' ' + element.escaladoa.apellido_usuario)
        }
      }
      // Data Analistas Calidad
      for (let index = 0; index < DataMes.analistas.analistasLista.length; index++) {
        const element = DataMes.analistas.analistasLista[index]
        this.labels_calidad.push(element.escaladoa.nombre_usuario + ' ' + element.escaladoa.apellido_usuario)
      }
      // Data tipo de requerimiento
      for (let index = 0; index < items.tiporequerimientos.length; index++) {
        const element = items.tiporequerimientos[index]
        this.labels_tipoReq.push(element.descripcion)
      }
      // Data Intancia
      for (let index = 0; index < DataMes.instanciaBarraF.instanciaBarraListadoF.length; index++) {
        const element = DataMes.instanciaBarraF.instanciaBarraListadoF[index]
        this.labels_instancia.push(element.descripcion)
      }
      // Data Area
      for (let index = 0; index < items.areas.length; index++) {
        const element = items.areas[index]
        if (element.descripcion !== 'NO APLICA' && element.descripcion !== 'OTRO') {
          this.labels_area.push(element.descripcion)
        }
      }
    },
    organizarDataMesProductoJS (fil, col) {
      let cantidadTotal = 0
      const element1 = this.DataMes.productos
      if (element1.length > 0) {
        for (let index = 0; index < element1.length; index++) {
          const element2 = element1[index]
          if (col === 0) { // Suma total De Cada Producto
            if (element2.producto_id === fil) {
              cantidadTotal = cantidadTotal + element2.cantidad
            }
          }
          if (col === 8) { // Cantidad de Casos Correo
            if (element2.subestado_id === col) {
              return element2.cantidad
            }
          } else if (fil > 0) { // Suma total De todos Sin producto
            if (element2.producto_id === fil && element2.subestado_id === col) {
              return element2.cantidad
            }
          }
        }
      }
      if (cantidadTotal > 0) {
        return cantidadTotal
      }
    },
    organizarDataMesAnalistasJS (fil, col) {
      let cantidadTotal = 0
      let filACt = 0
      const element1 = this.DataMes.analistas.analistasData
      if (element1.length > 0) {
        for (let index = 0; index < element1.length; index++) {
          const element2 = element1[index]
          if (col === 0) { // Suma total De Cada Analista
            if (filACt !== fil) {
              filACt = fil
              var sumDataCD = false
              var sumDataC = false
            }
            const element3 = this.DataMes.analistas.analistasDataCD
            if (sumDataCD === false) {
              for (let index = 0; index < element3.length; index++) {
                const element4 = element3[index]
                if (element4.id_usuariocerro === fil) {
                  sumDataCD = true
                  cantidadTotal = cantidadTotal + element4.cantidad
                  break
                }
              }
            }
            if (element2.id_escaladoa === fil) {
              cantidadTotal = cantidadTotal + element2.cantidad
              const element5 = this.DataMes.analistas.analistasDataC
              if (sumDataC === false) {
                for (let index = 0; index < element5.length; index++) {
                  const element6 = element5[index]
                  if (element6.id_usuariocerro === fil) {
                    sumDataC = true
                    cantidadTotal = cantidadTotal + element6.cantidad
                    break
                  }
                }
              }
            }
          }
          if (col === 4) { // Suma total De Cada Cerrado Duplicado
            const element5 = this.DataMes.analistas.analistasDataCD
            for (let index = 0; index < element5.length; index++) {
              const element6 = element5[index]
              if (element6.id_usuariocerro === fil && element6.subestado_id === col) {
                return element6.cantidad
              }
            }
          }
          if (col === 6) { // Suma total De Cada Cerrado
            const element7 = this.DataMes.analistas.analistasDataC
            for (let index = 0; index < element7.length; index++) {
              const element8 = element7[index]
              if (element8.id_usuariocerro === fil && element8.subestado_id === col) {
                return element8.cantidad
              }
            }
          }
          if (fil > 0) { // Cantidad cada fila
            if (element2.id_escaladoa === fil && element2.subestado_id === col) {
              return element2.cantidad
            }
          }
        }
      }
      if (cantidadTotal > 0) {
        return cantidadTotal
      }
    },
    organizarDataMesMotivoJS (fil, col) {
      let cantidadTotal = 0
      const element1 = this.DataMes.dataMotivosTotales.dataMotivos
      if (element1.length > 0) {
        for (let index = 0; index < element1.length; index++) {
          const element2 = element1[index]
          if (col === 0) { // Suma total De Cada Producto
            if (element2.motivo_id === fil) {
              cantidadTotal = cantidadTotal + element2.cantidad
            }
          } else if (fil > 0) { // Suma total De todos Sin producto
            if (element2.motivo_id === fil && element2.subestado_id === col) {
              return element2.cantidad
            }
          }
        }
      }
      if (cantidadTotal > 0) {
        return cantidadTotal
      }
    }
  }
}
