<template>
  <div style="width:100%">
 <v-container>
  <v-row >
          <v-col md="3" align-self="center">
          Selecciona la fecha 'Año-Mes'
          </v-col >
          <v-col md="3" align-self="center">
            <v-text-field v-model="anio" label="AÑO" type="number"></v-text-field>
          </v-col>
          <v-col md="3" align-self="center">
            <v-autocomplete v-model="selectMonth" :items="month" @change="selectMes" item-text="descripcion" item-value="id" :color="colores.primario" label="Mes *"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="selectMonth != 0">
          <v-col md="3" align-self="center">
          Selecciona el área
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selectArea" :items="areaUser" @change="selectAreas"  solo rounded item-text="descripcion" item-value="id" label="Areas"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="users.length > 0">
          <v-col md="3" align-self="center">
          Selecciona el usuario a auditar
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selectAsesor" :items="users" @change="selectAsesores"  solo rounded item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" label="Usuario"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="this.pqrsUser.length > 0">
  <v-col md="3" align-self="center">
          Actualiza las PQRS a asignar
          </v-col >
          <v-col md="5" align-self="center"> <v-autocomplete v-model="selectPqrsUser" multiple :items="pqrsUser" solo rounded item-text="codigo" item-value="id" label="PQRS ASESOR"></v-autocomplete></v-col>
          <v-col md="1" align-self="center"></v-col>
          <v-col md="3" > <v-btn @click="refrescar" block > <v-icon>mdi-refresh</v-icon> </v-btn></v-col>
  </v-row>
  <v-row no-gutters v-if="this.selectPqrsUser.length > 0">
  <v-btn
  block
  color="primary"
  elevation="8"
  @click="asignarPQRS"
  >Asignar</v-btn>
  </v-row>
 </v-container>
 <v-layout row justify-center>
  <v-dialog v-model="esperar" persistent max-width="450">
    <v-card :color="colores.primario">
        <v-card-text class="pt-4">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</v-layout>
 <v-row justify="space-around">
      <v-dialog v-model="dialogAlertaError"  transition="dialog-top-transition" >
        <template >
        <v-alert type="error"> {{message}} </v-alert>
        </template>
      </v-dialog>
  </v-row>
  </div>
</template>

<script>

import recCalidad from '@/js/rec_calidad.js'
export default {
  name: 'asignarCalidadComponent',
  mixins: [recCalidad],
  props: ['dialog', 'session', 'colores'],
  data: () => ({
    users: [],
    selectAsesor: '',
    selectAsesorReasignar: '',
    pqrsUser: [],
    selectPqrsUser: [],
    selectArea: '',
    areaUser: [{ id: 5, descripcion: 'ODT' }, { id: 14, descripcion: 'ANALISTAS' }, { id: 15, descripcion: 'FRAUDE' }, { id: 16, descripcion: 'RADICADORES' }],
    dialogAlertaError: false,
    message: '',
    esperar: false,
    anio: new Date().getFullYear(),
    month: [{ id: 1, descripcion: 'Enero' }, { id: 2, descripcion: 'Febrero' }, { id: 3, descripcion: 'Marzo' }, { id: 4, descripcion: 'Abril' }, { id: 5, descripcion: 'Mayo' }, { id: 6, descripcion: 'Junio' }, { id: 7, descripcion: 'Julio' }, { id: 8, descripcion: 'Agosto' }, { id: 9, descripcion: 'Septiembre' }, { id: 10, descripcion: 'Octubre' }, { id: 11, descripcion: 'Noviembre' }, { id: 12, descripcion: 'Diciembre' }],
    selectMonth: 0
  }),
  created () {
  },
  watch: {
    dialog: {
      immediate: true,
      handler (newVal, oldVal) {
        this.users = []
        this.selectAsesor = ''
        this.selectAsesorReasignar = ''
        this.pqrsUser = []
        this.selectPqrsUser = []
        this.selectArea = ''
        this.selectMonth = 0
      }
    }
  },
  methods: {
    selectMes () {
      this.users = []
      this.selectAsesor = ''
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.selectArea = ''
    },
    selectAreas () {
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.rec_get_asesor(this.selectArea)
    },
    selectAsesores () {
      this.esperar = true
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.rec_bandeja_mis_pqrs_calidad(this.selectAsesor, this.selectArea, this.anio, this.selectMonth)
    },
    asignarPQRS () {
      this.rec_asignar_pqrs_calidad(this.selectPqrsUser, this.selectAsesor, this.session.id)
      this.cerrarVentana()
      this.users = []
      this.selectAsesor = ''
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.selectArea = ''
    },
    alertError () {
      if (this.dialogAlertaError) {
        this.dialogAlertaError = true
        setTimeout(() => {
          this.message = ''
          this.dialogAlertaError = false
        }, 5000)
      }
    },
    refrescar () {
      this.esperar = true
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.rec_bandeja_mis_pqrs_calidad(this.selectAsesor, this.selectArea, this.anio, this.selectMonth)
    },
    cerrarVentana () {
      this.$emit('closed_ventana')
    }
  }
}
</script>
