<template>
<div style="width:100%">
  <v-card>
      <v-card-text>
        <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">TIEMPO PROMEDIO DE RESPUESTA POR TIPO DE REQUERIMIENTO</span></v-toolbar>
        <br>
        <v-row no-gutters>
              <v-col cols="12">
                <apexcharts width="100%" height="300px" type="bar" :options="chartOptions" :series="series"></apexcharts>
              </v-col>
        </v-row>
      </v-card-text>
  </v-card>
  <br>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'estadisticas_mes_promedio_tipoComponent',
  components: {
    apexcharts: VueApexCharts
  },
  mixins: [recFunciones, recEstadisticas],
  props: ['colores', 'objMes', 'selMes'],
  data: () => ({
    series: [{ data: [] }],
    labels: [],
    color: ['#2196F3', '#4CAF50', '#9C27B0', '#673AB7']
  }), // FIN DE DATA
  created () {
    this.grafica()
  }, // FIN DE CREATED
  watch: { }, // FIN DE WATCH
  computed: {
    chartOptions () {
      return {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        categories: this.labels,
        plotOptions: { bar: { columnWidth: '20%', distributed: true } },
        dataLabels: { enabled: false },
        legend: { show: false },
        xaxis: {
          categories: this.labels,
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        }
      }
    }
  },
  methods: {
    grafica () {
      const tipos = this.objMes.data.promedio_tipos
      this.labels = []
      const newData = []
      for (let i = 0; i < tipos.length; i++) {
        this.labels.push(tipos[i].descripcion)
        newData.push(tipos[i].dias)
      }
      this.series = [{ name: 'Promedio', data: newData }]
    }

  } // FIN DE METODOS
}
</script>
