import axios from 'axios'
export default {
  methods: {
    rec_anualnew (pAnio) {
      this.objAnual.general = []
      this.objAnual.anual = []
      this.loadingAnual = true
      this.selMes.numero = 0
      const urlroute = this.$store.getters.getUrl + 'estadisticanew_anula/' + pAnio
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.objAnual = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAnual = false
      })
    },
    rec_mesnew (pAnio, pMes) {
      this.loadingMes = true
      const urlroute = this.$store.getters.getUrl + 'estadisticanew_mes/' + pAnio + '/' + pMes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.objMes.data = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMes = false
        this.objMes.token = this.objMes.token + 1
      })
    },
    generarnew_excelFinal (anio, mes) {
      const urlroute = this.$store.getters.getUrl + 'excelFinal/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Reporte_' + mes + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelAsignaciones (anio, mes) {
      /* const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      */
      const urlroute = this.$store.getters.getUrl + 'excelAsignaciones/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Asignaciones_' + mes + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelEscalamientos (anio, mes) {
      /*
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      */
      const urlroute = this.$store.getters.getUrl + 'excelEscalamientos/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Escalamientos_' + mes + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelAnalisis (anio, mes) {
      /*
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      */
      const urlroute = this.$store.getters.getUrl + 'excelAnalisis/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Analisis_' + mes + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    },
    generar_excelReplicas (anio, mes) {
      /*
      const mesLet = mes.substr(0, 3)
      mes = mes.substr(3, 2)
      if (mes > 0 && mes < 10) {
        mes = '0' + mes
      }
      */
      const urlroute = this.$store.getters.getUrl + 'excelReplica/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        responseType: 'blob' // importante
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date().toLocaleString('en-CO', { timeZone: 'America/Bogota', hour12: false })
        const reg = /\//g
        today = today.replace(reg, '-')
        this.esperar = false
        link.setAttribute(
          'download',
          'Replicas_' + mes + '_' + today + '.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        this.item = ''
        this.date = ''
        this.date2 = ''
      }).catch(error => {
        this.text_snack = []
        this.snackbar_error = true
        const events = { 'some-index': false }
        const key = 'some-index'

        if (Object.prototype.hasOwnProperty.call(events, key)) {
          // Esto se ejecutaria sin desencadenar la excepcion
          console.log('El objeto tiene la propiedad')
        }
        console.error('este es un error', error)
      })
    }
  }
}
