<template>
<div style="width:100%">
  <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>REASIGNAR</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <!-- <estadisticas :session="session" :colores="colores" :rules="rules"></estadisticas> -->
        <reasignarComponent :dialog="dialog" @closed_ventana="alert"></reasignarComponent>
        <reasignarAlert :dialogalert="dialogalert"></reasignarAlert>

      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import reasignarComponent from './reasignarComponent.vue'
import reasignarAlert from './resignarAlertComponent.vue'

export default {
  name: 'reasignarDialogComponent',
  components: {
    reasignarComponent,
    reasignarAlert
  },
  props: ['dialog', 'session', 'colores', 'rules'],
  data: () => ({
    dialogalert: false
  }),
  methods: {
    alert () {
      this.dialogalert = true
      setTimeout(() => {
        this.dialogalert = false
        this.$emit('closed_reasignar')
      }, 2000)
    },
    cerrarVenta () {
      this.$emit('closed_reasignar')
    }
  }
}
</script>
