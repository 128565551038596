<template>
<div style="width:100%">
  <v-card>
      <v-card-text>
        <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">CANTIDAD DE CASOS POR MOTIVO DE QUEJA O RECLAMO/TUTELA Y ESTADO DE LOS CASOS</span></v-toolbar>
        <br>
        <v-row no-gutters>
              <v-col cols="12" md="3" style="font-size:10px">MOTIVOS</v-col>
              <v-col cols="12" md="1" ><v-card color="white" class="text-center" style="font-size: 10px;">TOTAL</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="brown" dark class="text-center" style="font-size: 10px;">MIC</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="indigo" dark class="text-center" style="font-size: 10px;">COR</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="pink" dark class="text-center" style="font-size: 10px;">SFC</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="blue" dark class="text-center" style="font-size: 10px;">ASI</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="amber" dark class="text-center" style="font-size: 10px;">ESC</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="deep-purple lighten" dark class="text-center" style="font-size: 10px;">PRO</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="purple" dark class="text-center" style="font-size: 10px;">DUP</v-card></v-col>
              <v-col cols="12" md="1" ><v-card color="green" dark class="text-center" style="font-size: 10px;">CER</v-card></v-col>
        </v-row>
        <div v-for="( motivo, index) in objMes.data.motivos2" :key="index">
            <v-row no-gutters>
                <v-col cols="12" md="3" style="font-size:10px">{{ motivo.descripcion }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.total }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.micrositio }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.correo }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.sfc }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.analista }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.escalamiento }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.enprorroga }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.duplicado }}</v-col>
                <v-col cols="12" md="1" class="text-center">{{ motivo.cerrado }}</v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
      </v-card-text>
  </v-card>
  <br>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'

export default {
  name: 'estadisticas_mes_motivo2Component',
  components: {
  },
  mixins: [recFunciones, recEstadisticas],
  props: ['colores', 'objMes', 'selMes'],
  data: () => ({
  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: { }, // FIN DE WATCH
  computed: { },
  methods: {

  } // FIN DE METODOS
}
</script>
