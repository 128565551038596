<template>
<div style="width:100%">
  <v-card>
      <v-card-text>
        <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">REPORTES DE EXCEL</span></v-toolbar>
        <br>
        <v-row>
          <v-col cols="12" md="2" align="center" align-self="center">
            <v-btn block @click="generarExcel('Final')" :color="colores.green" dark>EXCEL DE QR'S</v-btn>&nbsp;
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-btn block @click="generarExcel('Asignacion')" :color="colores.green" dark>EXCEL DE ASIGNACIONES</v-btn>&nbsp;
          </v-col>
          <v-col cols="12" md="3" align="center" align-self="center">
            <v-btn block @click="generarExcel('Escalamientos')" :color="colores.green" dark>EXCEL DE ESCALAMIENTOS</v-btn>&nbsp;
          </v-col>
          <v-col cols="12" md="2" align="center" align-self="center">
            <v-btn block @click="generarExcel('Analisis')" :color="colores.green" dark>EXCEL DE ANÁLISIS</v-btn>&nbsp;
          </v-col>
          <v-col cols="12" md="2" align="center" align-self="center">
            <v-btn block @click="generarExcel('Replicas')" :color="colores.green" dark>EXCEL DE RÉPLICAS</v-btn>&nbsp;
          </v-col>
        </v-row>
      </v-card-text>
  </v-card>
  <br>
  <v-card>
      <v-card-text>
        <v-toolbar :color="colores.primario" dark dense><span style="font-size:20px">TIPO DE REQUERIMIENTO ACUMULADO Y ESTADO DEL MES DE {{ selMes.descripcion }}</span></v-toolbar>
        <br>
        <v-layout row wrap>
                <v-flex md6 xs12>
                  <apexcharts1 width="100%" height="350px" type="pie" :options="chartOptions" :series="series_tipo"></apexcharts1>
                </v-flex>
                <v-flex md6 xs12>
                  <apexcharts2 width="100%" height="350px" type="pie" :options="chartOptionscerrados" :series="series_subEstados"></apexcharts2>
                </v-flex>
        </v-layout>
      </v-card-text>
  </v-card>
  <br>
  <productoComponent :colores="colores" :objMes="objMes" :selMes="selMes"></productoComponent>
  <analistaComponent :colores="colores" :objMes="objMes" :selMes="selMes"></analistaComponent>
  <promedioCalidadComponent :colores="colores" :objMes="objMes" :selMes="selMes"></promedioCalidadComponent>
  <promedioTipoComponent :colores="colores" :objMes="objMes" :selMes="selMes"></promedioTipoComponent>
  <promedioRecepcionComponent :colores="colores" :objMes="objMes" :selMes="selMes"></promedioRecepcionComponent>
  <promedioAreaComponent v-if="objMes.data.promedio_areas.length > 0" :colores="colores" :objMes="objMes" :selMes="selMes"></promedioAreaComponent>
  <motivo2Component :colores="colores" :objMes="objMes" :selMes="selMes"></motivo2Component>
  <motivo1Component :colores="colores" :objMes="objMes" :selMes="selMes"></motivo1Component>

</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticasnew.js'
import VueApexCharts from 'vue-apexcharts'
import productoComponent from '@/components/estadisticas/estadisticas_mes_productoComponent.vue'
import analistaComponent from '@/components/estadisticas/estadisticas_mes_analistaComponent.vue'
import motivo1Component from '@/components/estadisticas/estadisticas_mes_motivo1Component.vue'
import motivo2Component from '@/components/estadisticas/estadisticas_mes_motivo2Component.vue'
import promedioCalidadComponent from '@/components/estadisticas/estadisticas_mes_promedio_calidadComponent.vue'
import promedioTipoComponent from '@/components/estadisticas/estadisticas_mes_promedio_tipoComponent.vue'
import promedioRecepcionComponent from '@/components/estadisticas/estadisticas_mes_promedio_receComponent.vue'
import promedioAreaComponent from '@/components/estadisticas/estadisticas_mes_promedio_areaComponent.vue'

export default {
  name: 'estadisticas_mesComponent',
  components: {
    apexcharts1: VueApexCharts,
    apexcharts2: VueApexCharts,
    productoComponent,
    analistaComponent,
    motivo1Component,
    motivo2Component,
    promedioTipoComponent,
    promedioRecepcionComponent,
    promedioAreaComponent,
    promedioCalidadComponent
  },
  mixins: [recFunciones, recEstadisticas],
  props: ['colores', 'objMes', 'selMes', 'anio'],
  data: () => ({
    loadingExcel1: false,
    labels_tipo: ['SIN TIPO', 'SOLICITUD', 'QUEJA O RECLAMO', 'TUTELA', 'FELICITACION'],
    series_tipo: [0, 0, 0, 0, 0],
    labels_subEstados: ['ASIGNADO AL ANALISTA', 'CERRADO', 'CERRADO DUPLICADO', 'EN PRORROGA', 'ESCALAMIENTO', 'RECIBIDO MICROSITIO', 'RECIBIDO CORREO', 'RECIBIDO SFC'],
    series_subEstados: [0, 0, 0, 0, 0, 0, 0, 0],
    colors_subEstados: ['#2196F3', '#4CAF50', '#9C27B0', '#673AB7', '#FFC107', '#795548', '#3F51B5', '#E91E63']
  }), // FIN DE DATA
  created () {
    this.modulo1()
  }, // FIN DE CREATED
  watch: {
    'objMes.token': {
      immediate: false,
      handler (value) {
        // this.modulo1()
      }
    }
  }, // FIN DE WATCH
  computed: {
    chartOptions () {
      return {
        labels: this.labels_tipo
      }
    },
    chartOptionscerrados () {
      return {
        labels: this.labels_subEstados,
        colors: this.colors_subEstados
      }
    }
  },
  methods: {
    modulo1 () {
      const subestados = this.objMes.data.tortas.subestados
      const tipos = this.objMes.data.tortas.tipos

      for (let index = 0; index < subestados.length; index++) {
        if (subestados[index].subestado_id === 2) {
          this.series_subEstados[0] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 6) {
          this.series_subEstados[1] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 4) {
          this.series_subEstados[2] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 10) {
          this.series_subEstados[3] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 3) {
          this.series_subEstados[4] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 7) {
          this.series_subEstados[5] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 8) {
          this.series_subEstados[6] = subestados[index].cantidad
        } else if (subestados[index].subestado_id === 9) {
          this.series_subEstados[7] = subestados[index].cantidad
        }
      }

      for (let index = 0; index < tipos.length; index++) {
        if (tipos[index].tipo_id === 75) {
          this.series_tipo[1] = tipos[index].cantidad
        } else if (tipos[index].tipo_id === 76) {
          this.series_tipo[2] = tipos[index].cantidad
        } else if (tipos[index].tipo_id === 77) {
          this.series_tipo[3] = tipos[index].cantidad
        } else if (tipos[index].tipo_id === 78) {
          this.series_tipo[4] = tipos[index].cantidad
        } else {
          this.series_tipo[0] = tipos[index].cantidad
        }
      }
    },
    generarExcel (seleccion) {
      this.esperar = true
      switch (seleccion) {
        case 'Final':
          this.generarnew_excelFinal(this.anio, this.selMes.mes)
          break
        case 'Asignacion':
          this.generar_excelAsignaciones(this.anio, this.selMes.mes)
          break
        case 'Escalamientos':
          this.generar_excelEscalamientos(this.anio, this.selMes.mes)
          break
        case 'Analisis':
          this.generar_excelAnalisis(this.anio, this.selMes.mes)
          break
        case 'Replicas':
          this.generar_excelReplicas(this.anio, this.selMes.mes)
          break
        default:
          break
      }
    }
  } // FIN DE METODOS
}
</script>
