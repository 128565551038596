<template>
  <div style="width:100%">
 <v-container>
  <v-row no-gutters>
          <v-col md="3" align-self="center">
          Selecciona el área
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selected.selectArea" :items="areaUser" @change="selectAreas"  solo rounded item-text="descripcion" item-value="id" label="Areas"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="users.length > 0">
          <v-col md="3" align-self="center">
          Selecciona el usuario a restablecer
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selected.selectAsesor" :items="users" @change="selectAsesores"  solo rounded item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" label="Usuario"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="users.length > 0 && selected.selectAsesor !== '' && selected.selectAsesor !== undefined && selected.selectAsesor !== null">
          <v-col md="3" align-self="center">
          Ingresa la nueva contraseña
          </v-col >
          <v-col align-self="center">
                      <v-text-field
                      solo rounded
                      :rules="[required, min6]"
                      v-model="selected.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      label="contraseña nueva"
                      @click:append="show1 = !show1"
                      @keyup="igualdad"
          ></v-text-field>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="users.length > 0 && selected.selectAsesor !== '' && selected.selectAsesor !== undefined && selected.selectAsesor !== null">
          <v-col md="3" align-self="center">
          Confirmar contraseña
          </v-col >
          <v-col align-self="center">
                      <v-text-field
                      solo rounded
                      :rules="[required, min6, matchingPasswords ]"
                      v-model="selected.password1"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      label="confirmar contraseña nueva"
                      @click:append="show2 = !show2"
                      @keyup="igualdad"

          ></v-text-field>
          </v-col>
  </v-row>

  <v-row no-gutters v-if="selected.selectAsesor !== '' && selected.selectAsesor !== undefined && show3 === true && selected.selectAsesor !== null" >
  <v-btn
  block
  :disabled ="button"
  color="primary"
  elevation="8"
  @click="restablecer"
  >Restablecer</v-btn>
  </v-row>

 </v-container>
  </div>
</template>

<script>

import recResignar from '@/js/rec_reasignar.js'
export default {
  name: 'restablecerComponent',
  components: {
  },
  mixins: [recResignar],
  props: ['rules', 'dialog'],
  data: () => ({
    users: [],
    button: false,
    selected: [{ selectAsesor: '' }, { password: '' }, { password1: '' }, { selectArea: '' }],
    show1: false,
    show2: false,
    show3: false,
    areaUser: [{ id: 5, descripcion: 'ODT' }, { id: 14, descripcion: 'ANALISTAS' }, { id: 15, descripcion: 'FRAUDE' }, { id: 16, descripcion: 'RADICADORES' }, { id: 17, descripcion: 'SUPERVISORES' }]
  }),
  created () {
  },
  watch: {
    dialog: {
      immediate: true,
      handler (newVal, oldVal) {
        this.users = []
        this.selected = [{ selectAsesor: '' }, { password: '' }, { password1: '' }, { selectArea: '' }]
        this.show1 = false
        this.show2 = false
        this.show3 = false
      }
    }
  },
  methods: {
    required: function (value) {
      if (value) {
        return true
      } else {
        return 'Requerido.'
      }
    },
    min6: function (value) {
      if (value.length > 4) {
        return true
      } else {
        this.show3 = false
        return 'La contraseña debe tener más de 4 caracteres.'
      }
    },
    matchingPasswords: function () {
      if (this.selected.password === this.selected.password1 && this.selected.password1 !== '' && this.selected.password.length > 4) {
        this.show3 = true
        return true
      } else {
        this.show3 = false
        return 'Las contraseñas no coinciden.'
      }
    },
    selectAreas () {
      this.selected.password = ''
      this.selected.password1 = ''
      this.rec_get_asesor(this.selected.selectArea)
    },
    selectAsesores () {
      this.rec_bandeja_mis_pqrs(this.selected.selectAsesor)
    },
    restablecer () {
      this.rec_restablecer_clave(this.selected.selectAsesor, this.selected.password)
      this.selected.password = ''
      this.selected.password1 = ''
      this.selected.selectAsesor = ''
      this.selected.selectArea = ''
      this.users = []
      this.show3 = false
      this.cerrarVentana()
    },
    cerrarVentana () {
      this.$emit('closed_ventana')
    },
    igualdad () {
      this.selected.password === this.selected.password1 ? this.button = false : this.button = true
    }
  }
}
</script>
