<template>
  <div style="width:100%">
 <v-container>
  <v-row no-gutters>
          <v-col md="3" align-self="center">
          Selecciona el área
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selectArea" :items="areaUser" @change="selectAreas"  solo rounded item-text="descripcion" item-value="id" label="Areas"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="users.length > 0">
          <v-col md="3" align-self="center">
          Selecciona el usuario quien tiene las PQRS
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selectAsesor" :items="users" @change="selectAsesores"  solo rounded item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" label="Usuario"></v-autocomplete>
          </v-col>
  </v-row>
  <v-row no-gutters v-if="this.pqrsUser.length > 0 && this.selectAsesor !== '' && this.selectAsesor !== undefined && selectAsesor !== null">
  <v-col md="3" align-self="center">
          Selecciona las PQRS a reasignar
          </v-col >
          <v-col align-self="center"> <v-autocomplete v-model="selectPqrsUser" @change="usuarioInicial" multiple :items="pqrsUser" solo rounded item-text="codigo" item-value="id" label="PQRS ASESOR"></v-autocomplete>
</v-col>
<v-col>
  <v-switch
      v-model="switch1"
      color="success"
      label="Seleccionar Todos"
      @change="seleccionQR"
    ></v-switch>
</v-col>
  </v-row>

  <v-row no-gutters v-if="this.selectPqrsUser.length > 0 && this.selectAsesor !== '' && this.selectAsesor !== undefined && selectAsesor !== null ">
          <v-col md="3" align-self="center">
          Selecciona el usuario a quien se le reasignara las PQRS
          </v-col >
          <v-col align-self="center">
                      <v-autocomplete v-model="selectAsesorReasignar" :items="users2"  solo rounded item-text="usuario.nombre_usuario" item-value="usuario.id_usuario" label="Usuario"></v-autocomplete>
          </v-col>
  </v-row>

  <v-row no-gutters v-if="this.selectAsesorReasignar !== '' && this.selectAsesorReasignar !== undefined && selectAsesorReasignar !== null && this.selectPqrsUser.length > 0">
  <v-btn
  block
  color="primary"
  elevation="8"
  @click="reasignarPQRS"
  >Reasignar</v-btn>
  </v-row>
 </v-container>
  </div>
</template>

<script>

import recResignar from '@/js/rec_reasignar.js'
export default {
  name: 'reasignarComponent',
  components: {
  },
  props: ['dialog'],
  mixins: [recResignar],
  data: () => ({
    users: [],
    users2: [],
    switch1: false,
    selectAsesor: '',
    selectAsesorReasignar: '',
    pqrsUser: [],
    selectPqrsUser: [],
    selectArea: '',
    areaUser: [{ id: 5, descripcion: 'ODT' }, { id: 14, descripcion: 'ANALISTAS' }, { id: 15, descripcion: 'FRAUDE' }, { id: 16, descripcion: 'RADICADORES' }]
  }),
  created () {
  },
  watch: {
    dialog: {
      immediate: true,
      handler (newVal, oldVal) {
        this.users = []
        this.users2 = []
        this.selectAsesor = ''
        this.selectAsesorReasignar = ''
        this.pqrsUser = []
        this.selectPqrsUser = []
        this.selectArea = ''
      }
    }
  },
  methods: {
    selectAreas () {
      this.selectAsesorReasignar = ''
      this.selectPqrsUser = []
      this.rec_get_asesor(this.selectArea)
    },
    selectAsesores () {
      this.selectAsesorReasignar = ''
      this.selectPqrsUser = []
      this.rec_bandeja_mis_pqrs(this.selectAsesor)
    },
    reasignarPQRS () {
      this.rec_reasignar_pqrs(this.selectPqrsUser, this.selectAsesor, this.selectAsesorReasignar)
      this.users = []
      this.users2 = []
      this.selectAsesor = ''
      this.selectAsesorReasignar = ''
      this.pqrsUser = []
      this.selectPqrsUser = []
      this.selectArea = ''
      this.cerrarVentana()
    },
    cerrarVentana () {
      this.$emit('closed_ventana')
    },
    seleccionQR () {
      this.switch1 ? this.selectPqrsUser = this.pqrsUser : this.selectPqrsUser = []
      this.usuarioInicial()
    },
    usuarioInicial () {
      for (let index = 0; index < this.users2.length; index++) {
        const element = this.users2[index]
        if (this.selectAsesor === element.id_usuario) {
          this.users2.slice(index, 1)
        }
      }
      this.users2 = this.users.filter((item) => item.id_usuario !== this.selectAsesor)
    }
  }
}
</script>
