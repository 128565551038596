<template>
<div style="width:100%">
  <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>RESTABLECIMIENTO DE CONTRASEÑA</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
       <restablecerComponent :dialog="dialog" :rules="rules" @closed_ventana="alert"></restablecerComponent>
      <restablecerAlert :dialogalert="dialogalert"></restablecerAlert>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import restablecerComponent from './restablecerComponent.vue'
import restablecerAlert from './restablecerAlertComponent.vue'
export default {
  name: 'restablecerDialogComponent',
  components: {
    restablecerComponent,
    restablecerAlert
  },
  props: ['dialog', 'session', 'colores', 'rules'],
  data: () => ({
    dialogalert: false
  }),
  methods: {
    alert () {
      this.dialogalert = true
      setTimeout(() => {
        this.dialogalert = false
        this.$emit('closed_restablecer')
      }, 2000)
    },
    cerrarVenta () {
      this.$emit('closed_restablecer')
    }
  }
}
</script>
